import PaleonymsTimeFilter from '../utils/PaleonymsTimeFilter';

var TreeView = function(map_app) {
    'use strict';
    if (!(this instanceof TreeView)) {

        throw new Error('TreeView has to be constructed with the new keyword.');
    }

    this.map_app = map_app;

    this.treeNode = document.getElementById('cpList');

    var selectedNode;
    var _this = this;
    var clear_btn = document.getElementById('clear');
    var reset_btn = document.getElementById('rst');
    var search_input = document.getElementById('isci');
    var search_btn = document.getElementById('ok');
    var toggle_settings_btn = document.getElementById('srch-sttngs-btn');

    this.setSelectedNode = function(nodeRef)
    {
        selectedNode = nodeRef;
    }

    this.getSelectedNode = function()
    {
        return selectedNode;
    }
    
    const suggestionSelected = function(e)
    {
        e.stopPropagation();
        console.log(`suggestionSelected!`, e.which, e.key, e.type)
        
        search_input.value = e.target.dataset.name;
        search_input.focus();
        //https://www.geeksforgeeks.org/how-to-place-cursor-position-at-end-of-text-in-text-input-field-using-javascript/
        //
        outputNode.innerHTML = '';
    }

    /*
    const outputNode = document.getElementById('suggestions');
    // Confirmation/selection of suggestion
    outputNode.addEventListener('mousedown', suggestionSelected);
    let suggestionNodes;
    search_input.autocomplete = 'off';
    // Listen to input on textfield.
    search_input.addEventListener('keyup', function(e){
        e.stopPropagation();
       
        clear_btn.classList.add('active');

        if(e.which === 13)
        {
            return false;
        }
        // Create suggestions if 3 character or more on input
        if(search_input.value.length < 3)
        {
            outputNode.innerHTML = "";
        }
        if(search_input.value.length > 2)
        {
            let uList = document.createElement('UL');
            let results = _this.map_app.list.getItemNamesByName(search_input.value);
            results.forEach(function(item, i){
                let line = document.createElement('LI');
                    line.tabIndex = -1;
                line.innerHTML = item;
                line.dataset.name = item;
                uList.appendChild(line);
            });
            outputNode.innerHTML = "";
            outputNode.appendChild(uList);
            //console.log();
        }
        suggestionNodes = outputNode.getElementsByTagName('LI');


        // ArrowDown move focus to suggestions.
        if(e.which === 40)
        {
            suggestionNodes[0].focus();
        }
        
        });

        let i = 0;
        // Listen to keyup on outputNode.
        outputNode.addEventListener('keyup', function(e){
            e.stopPropagation();
            if(e.which === 40)
            {
                let next = suggestionNodes[++i];
                if(!next)
                {
                    console.log(`last suggestion reached`);
                    i = 0;
                    next = suggestionNodes[0];
                }
                next.focus();
            }
            if(e.which === 38)
            {
                console.log(`case is ${e.which}, index is ${i}`);
                let next = suggestionNodes[--i];
                if(!next)
                {
                    search_input.focus();
                    return false;
                }
                next.focus();
            }
            if(e.which === 13)
            {
                console.log(`case is ${e.which}`);
                suggestionSelected(e);
            }
            

    });
    */
    // Clear search field
    clear_btn.addEventListener('mousedown', function(e){
        console.log('Click on clear_btn');
        _this.clear_search({
            clear_btn: clear_btn,
            search_input: search_input
        });
    });

    // Listeninig for click on reset button
    reset_btn.addEventListener('mousedown', function(e) {
        e.preventDefault();

        //setTimeout(function(){_this.clear()}, 50);
        setTimeout(function(){
            _this.map_app.updateApp(_this.map_app.list.getData());
        }, 50);
        setTimeout(function(){reset_btn.classList.remove('active')}, 50);
        _this.clear_search({
            clear_btn: clear_btn,
            search_input: search_input
        });
    });

    toggle_settings_btn.addEventListener('mousedown', function(evt){
        evt.preventDefault();
        evt.stopPropagation();
        document.getElementById('srch-sttngs-toggle').checked ^= 1;
    })

    //form to filter data.;
    this.filter_form = document.getElementById('search_1');
    
    //console.log(this.filter_form);

    this.handleSubmit = function(e) {
        e.preventDefault();
        
        var nodeReferences = _this.map_app.getFilterNodeReferences();

        var regionNode = nodeReferences.regionNode;
        var typologyNode = nodeReferences.typologyNode;
        var toggle_markers = nodeReferences.toggle_markers;
        var nameSearchNode = nodeReferences.nameSearchNode;
        var time_selector = nodeReferences.time_selector;
        
        var search_value = _this.filter_form.isci.value;
        //console.log(`TreeView search form submitted. ${search_value}`);
        var region = _this.map_app.region;
        let test;
        if (test = _this.map_app.list.getData())
        {
            var filtered = _this.map_app.list.filter({
                region: _this.map_app.selectedRegions.getSelectedValues(),
                name: nameSearchNode.value,
                metaTypes: _this.map_app.selectedTypes.getSelectedValues(),
                dates: _this.map_app.timeSlider.get(true),
            });
        }
        /*
        _this.clear();
        _this.updateApp(filtered, _this.map_app.list.getChildRecords());
        */
        _this.map_app.updateApp(filtered);
        //_this.map_app.list.appendData(nodes);
        reset_btn.classList.add('active');
        if(_this.treeNode.getElementsByTagName('LI').length === 1)
        {
            // Click on tree node
            _this.treeNode.getElementsByClassName('treeBranch')[0].click();
        }
    };
};

/**
 * Clear all children from div node.
 */
TreeView.prototype.clear = function() {
    while(this.treeNode.firstChild) {
        this.treeNode.removeChild(this.treeNode.firstChild);
    }
    return;
};

TreeView.prototype.select_node = function(targetNode) {
    //console.log('TreeView select_node method called');

    if(!targetNode)
    {
        return;
    }
    this.setSelectedNode(targetNode);

    var selectedNode = this.getSelectedNode();
    selectedNode.firstChild.style.backgroundColor = 'pink';
};

TreeView.prototype.deselect = function() {
    let selectedNode;
    if (selectedNode = this.getSelectedNode()) {
        selectedNode.firstChild.style.backgroundColor = ''; // Change this to toggle utility class and handle in style.css.
        selectedNode = null;
        this.map_app.select.getFeatures().clear();
    }
};

TreeView.prototype.clear_search = function(options) {
    var clear_btn = options.clear_btn;
    var search_input = options.search_input;

    setTimeout(function(){clear_btn.classList.remove('active')}, 50);
    setTimeout(function(){
        search_input.value = '';
        search_input.focus()
    }, 50);
};


TreeView.prototype.getPaleonyms = function(id) {
    //console.log(`getPaleonyms called. ID = ${id}.`);
    var spinner = new loadingSpinner();
    spinner.start('izpisi');

    fetch('/kartica.php?q=' + id, { ///api/toponym/
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },

    })
      .then(resp => resp.text())
      //.then(data => {document.getElementById('izpisi').innerHTML = data;})
      .then(data => {
        this.map_app.paleonymsRepository.setData({paleonyms:data, toponym_id: id}); 
        document.getElementById('izpisi').innerHTML = this.map_app.paleonymsRepository.getData();

        var dates = this.map_app.timeSlider.get(true);
        var paleonymsTimeFilter = new PaleonymsTimeFilter({
            dates: dates,
            paleonyms: document.querySelectorAll('.single-paleonym')
        });
        /*
        */
      })
      //.then(spinner.end())
};

TreeView.prototype.getSinglePaleonym = function(id)
{
    fetch('kartica_paleo.php?q=' + id, { ///api/paleonym/
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
      .then(resp => resp.text())
      .then(data => {document.getElementById('izpisi').innerHTML = data;})
};

/**
 * Method to remove any leaves that do not match filteredData criterion.
 * Runs only on toponyms. Should not be called on paleonyms.
 * 
 */
TreeView.prototype.prune = function(filteredData)
{
    const filtered_ids = filteredData.map(function({properties: {toponym_id}}){
        return toponym_id;
    })
    //console.log(`pruning `, {filtered_ids});
    
    //console.log({filtered_ids});
    
    var treeElements = Array.from(this.treeNode.querySelectorAll('.treeBranch'));
    var leaves = treeElements.filter(function({dataset: {parent_id}}){
        assertTypeEquality(parent_id, 'null');
        return parent_id !== 'null';
    });

    //console.log({leaves});
    for(var current of leaves)
    {
        var {dataset: {toponym_id}} = current;
        //console.log(toponym_id + ' ' + filtered_ids.indexOf(toponym_id));
        if(filtered_ids.indexOf(castAsInteger(toponym_id)) === -1)
        {   
            current.remove();
        }
    }
}

export default TreeView;