import TreeView from './TreeView';
import {boundingExtent} from 'ol/extent';
import * as olExtent from 'ol/extent';
import { FeatureStyles, SelectedStyle, HiddenStyle } from './FeatureStyles';

var PaleonymsTreeView = function(map_app)
{
	try
	{
		if(!(this instanceof PaleonymsTreeView))
		{
			throw new TypeError('Need to instantiate PaleonymsTreeView with the "new" keyword.');
		}
	}
	catch(err)
	{
		console.error(err.message);
		
	}
	var _this = this;
	var data;
	

	this.setData = function(dataload)
	{
		data = dataload;
		return this;
	}
	//return false;

	/**
	 * Method called when a node on the treeView is clicked. Selects features on map and 
	 * retrieves detailed records from the DB.
	 * @param  {DOM element} clickedNode [description]
	 * @return {[type]}             [description]
	 */
	this.getData = function(clickedNode){
		if (clickedNode.matches('span')) {
			clickedNode = clickedNode.parentElement;
		}
		if (clickedNode.matches('li')) {
			_this.deselect();
			_this.select_node(clickedNode);
			_this.map_app.clearOutput();

			try {
				// Clear any previous features on map.
				if((_this.map_app.select.getFeatures()))
				{
					_this.map_app.select.getFeatures().clear();
				}	
				
				// Make arrays from dataset variables. 
				const toponym_ids_array = clickedNode.dataset.toponym_ids.split(',');
				if(toponym_ids_array.length === 0)
				{
					throw new Error('This toponym is an orphan. Teminating script.');
				}

				const paleonym_ids_array = clickedNode.dataset.paleonym_ids.split(',').map(function(item){return parseInt(item, 10)});
				
				const selectedMarkers = [];
				let selectionExtent;
				let geometry;

				// REFACTORING 
				// 
				// If more than one marker, then iterate
				// 
				// Not selected, eval on no of markers. If one -> get paleonyms.
				// 
				// If more define and load more results.
				// Better even, get features from List store and load them on map. How slow would that be?

				//Set iterator counter to 0.
				let i = 0;
				
				while(i < toponym_ids_array.length)
				{
					// Method getMarkerByToponymId returns an array.
					let marker = _this.map_app.getMarkerByToponymId(toponym_ids_array[i]);

					if(geometry = marker[0].getGeometry())
					{
						switch(i){
						case 0: // Geometries constitute features. If marker objects from
							//in-memory store lack them, they will not be pushed to map.
							// If geometry property exist, extent can be set.
							//console.log(marker[0].getGeometry());
							selectionExtent = geometry.getExtent();
							break;
	
						default: olExtent.extend(selectionExtent, geometry.getExtent())
	
						}
	
					}
					selectedMarkers.push(marker[0]);
					i++;
				}

				if (geometry) { //Same if above (line 80) with switch clause. Refactor!
					_this.map_app.map.getView().fit(
						selectionExtent,
						{
							nearest: true,
							padding: [190,190,190,190],
							duration: 500,
							maxZoom: 14.2
						}
					)

					_this.map_app.notifications({
						message: false
					});
				} // if markers true ends.
				else
				{
					_this.map_app.notifications({
						type: 'warning',
						message: 'Ta objekt še ni lociran.'
					});
				}
				
				//console.log(selectedMarkers, selectedMarkers.length);
				
				if(selectedMarkers.length === 1)
				{
					_this.map_app.select.getFeatures().push(selectedMarkers[0]);
					// Selecting/displaying one toponym feature. Set feature text to paleonym.name.
				}

				if(selectedMarkers.length > 1)
				{
					_this.map_app.setCandidatePaleonymIDS(paleonym_ids_array);
				}

				if(selectedMarkers.length < 2)
				{
					_this.getSinglePaleonym(paleonym_ids_array[0]);

				}

				// If found_locations exists, remove it. 
				_this.map_app.removeVector({
					name: 'found_locations'
				});

				let newLayer = _this.map_app.defineNewVector({
					name:'found_locations',
					features: false
				});
				
				newLayer.getSource().addFeatures(selectedMarkers);
				_this.map_app.map.addLayer(newLayer);
			}
			catch (err)
			{
				console.error(err);
			};


			/*
			setTimeout(function(){
			//console.log(`Instantiating new Etymology.`);
			new Etymology(clickedNode.id);
			}, 50);
			*/
		}		
	}

	if(!this.treeNode.getAttribute('clickEvent')){
		this.treeNode.addEventListener('click', function(e){
			_this.getData(e.target);
		});
		this.treeNode.setAttribute('clickEvent', true);
	}

	TreeView.call(this, map_app);

	this.filter_form.removeEventListener('submit', this.handleSubmit);
  this.filter_form.addEventListener('submit', this.handleSubmit);
}

PaleonymsTreeView.prototype = new TreeView();
PaleonymsTreeView.prototype.constructor = PaleonymsTreeView;

PaleonymsTreeView.prototype.buildTree = function(dataload)
{
  var _this = this;
  
  //var floor = 0;
  //var cieling = 399;
  if(_this.setData(dataload) !== undefined){
  	try
		{
    	for (var i = 0; i < dataload.length; i++) {
        var record = dataload[i].properties;
        //var node;
        var line = document.createElement('li');
        var span = document.createElement('span');
      	
        line.dataset.name = record.name;
        line.dataset.search_name = record.search_name;
        line.dataset.toponym_ids = record.toponym_ids;
        line.dataset.paleonym_ids = record.paleonym_ids;
        
        span.innerHTML = record.name;
        line.appendChild(span);
        line.id = i;
        line.className = 'treeBranch';

        _this.treeNode.appendChild(line);
        
      }
      return;
    }
    catch(err)
    {
    	console.error(err.name, err.message);
    }
    console.log(`End of tree loading.`);
  }
}

export default PaleonymsTreeView;