import Etymology from './Etymology';

import TreeView from './TreeView';

var ToponymsTreeView = function(map_app)
{
	var _this = this;
	var data;

	// Not implemented in code below! remove
	this.setData = function(dataload)
	{
		data = dataload;
		return this;
	}

	this.groupDataByToponymId = function(dataload)
	{
		let result = [];
		dataload.forEach(function(record, i){
			var match = false;
			
			// Check if toponym_id already exists in result
			result.forEach(function(item, j){
				if(parseInt(item.properties.toponym_id) === parseInt(record.properties.toponym_id))
				{
					match = true;
				}
			});
			
			if(!match)
			{
				result.push(record);
			}

		});
		return result;
	}
	
	this.getData = function(clickedNode) {
		
		if (clickedNode.matches('span')) {
			clickedNode = clickedNode.parentElement;
		}

		if (clickedNode.matches('li')) {
			_this.deselect();
			_this.select_node(clickedNode);

			_this.map_app.clearOutput();

			_this.getPaleonyms(clickedNode.id);
			//console.log('Clicked node with toponym_id:', clickedNode.dataset.toponym_id);
			var markers = _this.map_app.getMarkerByToponymId(clickedNode.id);
			//console.log(markers);
			var geometry;

			//_this.map_app.select.getFeatures().clear();
			markers.forEach(function(marker, i){
				if (geometry = marker.getGeometry()) 
				{
					var mapView = _this.map_app.map.getView();
					mapView.fit(geometry, {
						nearest: true,
						padding: [190,190,190,190],
						duration: 500,
						maxZoom: 14.2,
					});

					//_this.map_app.map.addInteraction(_this.map_app.select);
					_this.map_app.select.getFeatures().push(marker);
					_this.map_app.notifications({
						message: false
					});
				}
				else
				{
					_this.map_app.map.getView().centerOn([1649262, 5799642], _this.map_app.map.getSize(), [0, 0]);
					_this.map_app.notifications({
						type: 'warning',
						message: 'Ta objekt še ni lociran.'
					});
				}
		});

			/*setTimeout(function(){
				//console.log(`Instantiating new Etymology.`);
				new Etymology(clickedNode.id);
			}, 50);*/
		}
	};


	if(!this.treeNode.getAttribute('clickEvent')){
	  this.treeNode.addEventListener('click', function(e){
	  	e.stopPropagation();
			var clickedNode = e.target;
			_this.getData(clickedNode);
			//console.log(`TreeView ${e.type} event triggered!`);
	  });
	  this.treeNode.setAttribute('clickEvent', true);
	}

	TreeView.call(this, map_app);

	this.filter_form.removeEventListener('submit', this.handleSubmit);
  this.filter_form.addEventListener('submit', this.handleSubmit);
}

ToponymsTreeView.prototype = new TreeView();
ToponymsTreeView.prototype.constructor = ToponymsTreeView;

ToponymsTreeView.prototype.item2domLine = function(ingest)
{
	try
	{
		if(ingest.toponym_id === undefined)
		{
			throw new Error('Missing property toponym_id.');
		}
		if(ingest.name === undefined)
		{
			throw new Error('Missing property name.');
		}
		if(ingest.search_name === undefined)
		{
			throw new Error('Missing property search_name.');
		}
		if(ingest.parent_id === undefined)
		{
			throw new Error('Property parent_id must not be undefined.');
		}
		if(ingest.region === undefined)
		{
			throw new Error('Missing property region.');
		}
	}
	var m = document.createElement('LI');
  var span = document.createElement('SPAN');
  m.id = ingest.toponym_id;
  m.className = 'treeBranch';
  m.dataset.toponym_id = ingest.toponym_id;
  m.dataset.name = ingest.name;
  m.dataset.search_name = ingest.search_name;
  m.dataset.parent_id = ingest.parent_id;
  m.dataset.region = ingest.region;//n;
  m.setAttribute('loading', 'lazy');
  span.innerHTML = ingest.name;
  m.append(span);
  if(m.dataset.parent_id != "")
  {
  	//console.log(m.dataset.parent_id);
  }
  return m;
}

ToponymsTreeView.prototype.buildTree = function(dataload)
{
	// dataload contains properties AS WELL AS geometries!
	//console.log('Incipit ToponymsTreeView.buildTree method.');
	try
	{
		if(!dataload){
			throw new Error('dataload parameter is undefined. This cannot be.');
		}
	}
	catch(err)
	{
		console.error(err.message);
	}

	//console.log(dataload);
	// var spinner = new loadingSpinner();
	// spinner.start('vsebina');
  var _this = this;
  try
  {
    var nodes = [];
    //var lookupList = {};
    //console.log(`nodes`, {nodes});

    if(dataload !== undefined){
    	var queue = _this.groupDataByToponymId(dataload);
			
    	//console.log(queue);
    	//var nodes;
    	//console.log(`nodes on redeclare`, {nodes});
    	//console.log(_this.treeNode);

      for (var i = 0; i < queue.length && 100; i++) {
          var record = queue[i].properties;
          var line = this.item2domLine(record);
          
          if(line.dataset.toponym_id === line.dataset.parent_id)
          {
          	console.log(`Parent is equal to child. Offending party is ${line.dataset.toponym_id}.`);
          	continue;
          }

          var childRecordsLookup = _this.map_app.list.getChildRecords;
          if(childRecordsLookup(record.toponym_id)){
          	childRecordsLookup = _this.groupDataByToponymId(childRecordsLookup(record.toponym_id));
        
          	// We have subrecords, create nested UL element.

	  				var ul = document.createElement('UL');
  					line.append(ul);

          	childRecordsLookup.forEach(function(child){
          		var subline = _this.item2domLine(child.properties);
          		line.querySelector('UL').append(subline);
          	})
          }

          //console.log(`testing for parent_id`, line.dataset.parent_id.length === 0)
          if(line.dataset.parent_id === "null")
          {
          	_this.treeNode.appendChild(line);
          }
          else
          {
          	nodes.push(line);
          }
      }

      // In case of search by name we have non orphaned child nodes
      // that need to be appended to DOM.
      //console.log({nodes});
      /*
      for (var i = 0; i < nodes.length; i++) {
        var record = nodes[i];
        
        //console.log({line});
	     	this.treeNode.appendChild(record);
      	
      }
      */

      //spinner.end();
	  }
  }
  catch(err)
  {
  	console.error('Error has occured. name=', err.name, '\n\nnmessage=', err.message/*, '\n\nfilename=', err.fileName, '\n\nlineNumber=', err.lineNumber, '\n\nstack=', err.stack*/);
  	return;
  }
};


ToponymsTreeView.prototype.compare2siblings = function(newNode)
{
	var _this = this;
	
	var siblings = Array.from(_this.treeNode.childNodes);
	let i = 0; // Iteration index.
	let result; // Results array for string comparison.

	while(i < siblings.length)
	{
		let comparison = newNode.dataset.name.toLowerCase().localeCompare(siblings[i].dataset.name.toLowerCase(), 'sl', {sensitivity: 'variant'});
		//console.log(comparison, newNode.dataset.name, siblings[i].dataset.name);
		// Sorting in the DOM tree.
		if(comparison > 0)
		{
			_this.treeNode.insertBefore(newNode, siblings[i].nextSibling);
		
		}
		if(comparison < 0)
		{
			_this.treeNode.insertBefore(newNode, siblings[i]);
			break;
		}

		++i;
	}

	if(siblings.length == 0)
	{
		_this.treeNode.appendChild(newNode);
	}
};

/**
 * [buildTreeInsertSort description]
 * @param  {[type]} dataload [description]
 * @return {[type]}          [description]
 */
ToponymsTreeView.prototype.buildTreeInsertSort = function(dataload)
{
	/**
	 * lookUp dictionary/hash to store all nodes already added to the page.
	 * Contains LI nodes.
	 */
	var _this = this;
	const nodes = {};
	
	/**
	 * A hook for a get method of all map features. Contains geo_json geometries 
	 * without a head.
	 */
	const lookUp = _this.map_app.list.rootLookupById;

	dataload.forEach(function(feature){
		// Get properties from feature.
		let { properties } = feature;
		// Destructure to needed individual properties.
		let { parent_id, toponym_id } = properties;
		// Convert feature properties to LI node.
		let newNode = _this.item2domLine(properties);


		// If no parent id, it is a 0 level node.
		if(!parent_id)
		{
			_this.compare2siblings(newNode);
			/**
			 * Handling subrecords
			console.log(newNode.dataset.toponym_id, newNode.dataset.name);
			console.log(childRecordsLookup(newNode.dataset.toponym_id));
			 */
			var childRecordsLookup = _this.map_app.list.getChildRecords;

		  if(childRecordsLookup(newNode.dataset.toponym_id)){
		  	childRecordsLookup = _this.groupDataByToponymId(childRecordsLookup(newNode.dataset.toponym_id));

		  	// We have subrecords, create nested UL element.

				var ul = document.createElement('UL');
				newNode.append(ul);

		  	childRecordsLookup.forEach(function(child){
		  		var subline = _this.item2domLine(child.properties);
		  		newNode.querySelector('UL').append(subline);
		  	})
		  }
		}
		else // We have a child node.
		{
			// Is parent node already on page?.
			let parent_node = nodes[parent_id];

			if(!parent_node)
			{
				// No parent node on page yet.
				//console.log(newNode, parent_id, toponym_id, name, search_name);
				// Get parent feature from list lookup dict.
				let parent_item = lookUp(parent_id);
				//console.log(parent_item);
				if(!parent_item)
				{
					console.error("Something wrong. No parent node in List lookup dict. ", newNode.dataset.name);
				}
				// Translate feature to LI node
				parent_node = _this.item2domLine(parent_item.properties);
				
				// Do comparison and render on page.
				_this.compare2siblings(parent_node, parent_node.dataset.name);
			}
			// Query parent node for child UL.
			let subUL = parent_node.querySelector('UL');
			if(!subUL)
			{
				subUL = document.createElement('UL');
			}
			parent_node.appendChild(subUL);
			subUL.appendChild(newNode);

			// Convert parent node to LI element

		}
		// Current item has been added to the page, add it to nodes dictionary.
		nodes[toponym_id] = newNode;  // node is of type DOM object!
	});
};

export default ToponymsTreeView;