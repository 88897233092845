/**
 * Class to cache in memory ajax response. Data can be re-accessed
 * when filtering with time slider. 
 */
var PaleonymsRepository = function()
{
	var dataSet;
	var toponym_id;

	//console.log(`PaleonymsRepository incipit.`);

	this.setData = function(options)
	{
		dataSet = options.paleonyms;
		toponym_id = options.toponym_id;
	}

	this.getData = function()
	{
		return dataSet;
	}

	this.getToponymId = function()
	{
		return toponym_id;
	}
}

export default PaleonymsRepository;