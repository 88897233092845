/**
* MultiCheckBox
*/

var MultiCheckBox = function(node)
{
  const _this = this;
  const inputNodes = node.getElementsByTagName('INPUT');
  
  const testAllSelected = function(){
    let first = true;    
    let result = true
    for(let checkbox of inputNodes){
      // Skipping first iteration

      if(first){
        //console.log({first})
        first = false;
        continue;
      }
      if(checkbox.checked === false)
      {
        result = false;
      }
    }
    return result;
  }
  
	var toggleAllNode = inputNodes[0];
  
	let first = true;
	for(let checkbox of inputNodes)
	{
	if(first){
	    first = false;
	    continue;
	}
	checkbox.addEventListener('change', function(evt){
	  evt.stopPropagation();
	  //console.log(testAllSelected());
	  testAllSelected() ? toggleAllNode.checked = true : toggleAllNode.checked = false;
	}) 
	};

	this.selectAllNodes = function(){
		for(node of inputNodes)
		{
			if(node.type === 'checkbox' && node.checked === false)
			{
				node.checked = true;
			}
		}
	}

	this.deselecAllNodes = function(){
		for(let node of inputNodes)
		{
			if(node.type === 'checkbox' && node.checked === true)
			{
				node.checked = false;
			}
		}
	}

	this.getSelectedValues = function(){
		let output = [];
    let first = true;
		for(node of inputNodes){
      if(first){
        first = false;
        continue;
      }
			if(node.checked){
				output.push(node.value);
			}
		}
		return output;
	}
  
    
	toggleAllNode.addEventListener('change', function(evt){
		evt.stopPropagation();
		toggleAllNode.checked ? _this.selectAllNodes() : _this.deselecAllNodes();
	})
  

	testAllSelected() ? toggleAllNode.checked = true : toggleAllNode.checked = false;

	return this;
}

export default MultiCheckBox;