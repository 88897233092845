var SearchTree = function(options){
  var map_app = options.map_app;
  var listNode = options.listNode;
  var find_input = options.input;
  var btn_clear = options.btn_clear;
  var btn_prev = options.btn_prev;
  var btn_next = options.btn_next;
  var span_current = options.span_current;
  var span_total = options.span_total;
  var current_result = {};
  var _this = this;
  var results = [];
  
  var list;

  this.populate_list = function()
  {
    list = listNode.getElementsByTagName('LI')
  }

  this.get_find_input = function()
  {
    return find_input;
  }

  this.get_map_app = function()
  {
    return map_app;
  }

  this.get_listNode = function()
  {
    return listNode;
  }

  this.get_list = function()
  {
    return list;
  }
  
  this.set_results = function(resultset)
  {
    results = resultset;
  }
  
  this.get_results = function()
  {
    return results;
  }
  
  this.set_current_result = function(node, index)
  {
    if(current_result.node){
      current_result.node.classList.remove('selected');
    }
    node.classList.add('selected');
    current_result = {
        node: node,
        index: index
    }
  }

  this.get_current_result = function()
  {
    return current_result;
  }

  this.set_current_position = function(index)
  {
    current_position = index;
  }
  
  this.get_current_position = function()
  {
    return current_position;
  }

  this.get_btnClear = function()
  {
    return btn_clear;
  }
  
  this.get_btnPrev = function(){
    return btn_prev;
  }

  this.get_btnNext = function(){
    return btn_next;
  }
  
  this.get_total = function()
  {
    return span_total;
  }
  
  this.get_current_span = function()
  {
    return span_current;
  }
  
  this.set_current_span = function(index)
  {
    span_current.innerHTML = index;
  }

  this.find_matches = function(value)
  {
    let query = value;
    if(!_this.get_list())
    {
      console.log(`populating list property.`)
      _this.populate_list()
    }
    _this.unselect_results_list();
    results = [];

    if(query.length > 2){
    //console.log(query);
      let pattern = new RegExp(query, 'i');


      Array.from(_this.get_list()).forEach(function(line, i){
        if(pattern.test(line.dataset.search_name))
          {
            results.push(line);
          }
      });
      if(results.length > 0){

        _this.set_current_result(results[0], 0);
        _this.set_results(results);
        //_this.update_state();
      }
    }
      _this.paint(results);
      _this.update_state();
    
  }

  span_current.innerHTML = 0;
  span_total.innerHTML = 0;
  
  // Event listeners
  find_input.addEventListener('input', function(evt){
    _this.find_matches(evt.target.value);
    //_this.get_btnClear().classList.remove('show');
    /*if(evt.target.value.length > 0)
    {
      console.log('adding show class');
      _this.get_btnClear().classList.add('show');
    }*/
  });

  find_input.addEventListener('keydown', function(evt){
    if(evt.key === 'Enter')
    {
      if(!_this.get_results())
      {
        _this.find_matches(evt.target.value);
      }
      _this.move_fwd();
      _this.update_state();
    }
  });
  
  btn_next.addEventListener('click', function(evt){
    _this.move_fwd();
    _this.update_state();
  })
  
  btn_prev.addEventListener('click', function(evt){
    _this.move_bk();
    _this.update_state();
  })

  btn_clear.addEventListener('click', function(evt){
    _this.reset();
    _this.find_matches(_this.get_find_input().value);
    _this.update_state();
    _this.get_find_input().focus();
  })
}

SearchTree.prototype.update_state = function(){
  console.log(this.get_current_result().node);
  let selected_node = this.get_current_result().node
  selected_node.scrollIntoView(true);
  this.get_map_app().tree.getData(selected_node);

  this.set_current_span(this.get_current_result().index + 1);
}

SearchTree.prototype.paint = function(foundArray){
  let span_total = this.get_total();
  let span_current = this.get_current_span();
  span_current.innerHTML = 0;
  span_total.innerHTML = foundArray.length;
  if(foundArray.length > 0)
  {
    span_current.parentElement.classList.add('show');
    this.get_btnPrev().classList.add('show');
    this.get_btnNext().classList.add('show');
    this.get_btnClear().classList.add('show');
    span_current.innerHTML = 1;      
  }
  else
  {
    span_current.parentElement.classList.remove('show');
    this.get_btnPrev().classList.remove('show');
    this.get_btnNext().classList.remove('show');
    this.get_btnClear().classList.remove('show');
  }
  
  foundArray.forEach(function(item, i){
    //console.log(item, i);
    item.classList.add('selected');
    
  })    
}

SearchTree.prototype.move_bk = function(){
  let current_result = this.get_current_result().node;
  let current_index = this.get_current_result().index;
  
  if(current_index > 0){
    // Move one index back
    let next_result = this.get_results()[current_index - 1];
    this.set_current_result(next_result, current_index - 1);
    
  }
  else
    {
      // Jump to last index.
      let last_index = this.get_results().length - 1;
      let next_result = this.get_results()[last_index];
      this.set_current_result(next_result, last_index);
    }
  //this.update_state();
}

SearchTree.prototype.move_fwd = function(){
  let current_result = this.get_current_result().node;
  let current_index = this.get_current_result().index;
  
  if(current_index + 1 < this.get_results().length){
    let next_result = this.get_results()[current_index + 1];
    this.set_current_result(next_result, current_index + 1);
    
  }
  else
    {
      let next_result = this.get_results()[0];
      this.set_current_result(next_result, 0);
      
    }
  //this.update_state();
}

SearchTree.prototype.unselect_results_list = function(){
  let selected = this.get_listNode().getElementsByClassName('selected');
  Array.from(selected).forEach(function(item){
    item.classList.toggle('selected');
  })
}

SearchTree.prototype.reset = function(){
  this.get_find_input().value = '';
  this.get_map_app().tree.deselect();
}

export default SearchTree;