/**
 * Escape unicode characters above ASCII range in order to compare
 * them in Regex statements.
 * Returns an escaped string.
 */
/**
 * [EscapeString description]
 * @param {[type]} string [description]
 */
var EscapeString = function(string)
{
	'use strict';
	//console.log('EscapeString:', string);
	this.arr_escaped = Array();
 	
	for(let i=0; i<string.length; i++)
	{
	let characterDec = string.charCodeAt(i);

		if(characterDec>127)
		{
			let character_hex = characterDec.toString(16);
			let unicode_esc = '\\u' + this.padNumber(character_hex, 4);
			this.arr_escaped.push(unicode_esc);
		}
		else
		{
			this.arr_escaped.push(string.charAt(i));
		}
	}
	return this;
};

/**
 * [padNumber description]
 * @param  {[type]} number [description]
 * @param  {[type]} length [description]
 * @return {[type]}        [description]
 */
EscapeString.prototype.padNumber = function(number, length)
{
	var str = number.toString();
	while (str.length < length) 
	{
		str = '0' + str;
	}
	return str;
};

/**
 * [get description]
 * @return {[type]} [description]
 */
EscapeString.prototype.get = function()
{
 	return this.arr_escaped.join('');
};

export default EscapeString;