import 'ol/ol.css';
import { Map, View } from 'ol';
import Overlay from 'ol/Overlay';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import VectorLayer from 'ol/layer/Vector';
import { Cluster, Vector as VectorSource } from 'ol/source';
import XYZ from 'ol/source/XYZ';
import OSM from 'ol/source/OSM';
import GeoJSON from 'ol/format/GeoJSON';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import GeometryCollection from 'ol/geom/GeometryCollection';
import Point from 'ol/geom/Point';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import { get as getProjection, addProjection, Projection } from 'ol/proj';
import { bbox as Bbox } from 'ol/loadingstrategy';
import { defaults as defaultInteractions, Select } from 'ol/interaction';
import Control from 'ol/control/Control';
import { defaults as defaultControls, ScaleLine } from 'ol/control';
import ToponymsTreeView from '/incl/js/map_appModules/ToponymsTreeView';
import PaleonymsTreeView from '/incl/js/map_appModules/PaleonymsTreeView';
import List from '/incl/js/map_appModules/List';
import { FeatureStyles, SelectedStyle, HiddenStyle } from '/incl/js/map_appModules/FeatureStyles';
import {altKeyOnly, click, pointerMove} from 'ol/events/condition';
import JsonStreamParser from './incl/js/map_appModules/JsonStreamParser';
import PaleonymsRepository from './incl/js/map_appModules/PaleonymsRepository';
// import NoUiSlider from './incl/js/map_appModules/NoUiSlider';
// Added from NoUISlider
import SearchTree from './incl/js/map_appModules/SearchTree';
import SearchFilters from './incl/js/map_appModules/SearchFilters';
import PaleonymsTimeFilter from './incl/js/utils/PaleonymsTimeFilter';
import MultiCheckBox from './incl/js/utils/MultiCheckBox';
import * as noUiSlider from 'nouislider/distribute/nouislider';
import 'nouislider/distribute/nouislider.css';
import wNumb from 'wnumb';


proj4.defs("EPSG:3912", "+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=-5000000 +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +units=m +no_defs");

//readup: https://www.vinaybhinde.in/2020/04/processing-large-files-in-node-js/
// parser for json with streams: https://www.npmjs.com/package/stream-json
// https://github.com/Viglino/ol-ext

register(proj4);



var markersData;
var source = new VectorSource({
    format: new GeoJSON({
        dataProjection: 'EPSG:3912',
        featureProjection: 'EPSG:3857'
    }),
    attributions: [
        'Podatki: <a href="https://egp.gu.gov.si/egp/">GURS e-Geodetski podatki</a> | ZRC SAZU Zgodovinski inštitut Milka Kosa | Inštitut Josef Štefan', 
    ] 
});

var MapApp = function(options) {
    'use strict';
    if (!(this instanceof MapApp)) {
        throw new TypeError('MapApp has to be constructed with the new keyword.');
    }
    if (typeof options !== 'object' && options.map && options.target && options.longlist) {
        throw new Error('The provided paramaters have to be provided as objects.')
    }
    if (!(options.map instanceof Map)) {
        throw new Error('Please provide a valid OpenLayers3 map object as a parameter to MapApp.');
    }
    
    var _this = this;
    var dates;
    var candidate_ids; // This is needed for Paleonyms site exclusively;
    this.setCandidatePaleonymIDS = function(data){
        try{
            if(data.constructor!==Array)
            {
                throw new Error('Input data needs to be of type array.');
            }
        }
        catch(err)
        {
            console.error(err);
            return;
        }
        candidate_ids = data;
    }
    this.getCandidatePaleonymIDS = function()
    {
        return candidate_ids;
    }

    this.map = options.map;
    this.target = options.target;
    this.featuresLayer;
    this.select = new Select({
        condition: click,
        hitTolerance: 3,
        style: SelectedStyle
    });

    /*
    if(this.map)
    {
        console.log(`map instantiated ${this.map instanceof Map}`)
    }
    */
    if(this.select)
    {
        //console.log(`select instantiated ${this.select instanceof Select}`)
        this.map.addInteraction(this.select);
    }
    
    this.paleonymsRepository = new PaleonymsRepository();

    this.markerSelection = function(e)
    {
        //console.log(`map event=${e.type}`);
        //console.log(`No. of selected features=${e.selected}`);
        //Grabbing features from event i. e. the clicked marker!
        var features = e.target.getFeatures();
        try {
            if (features.getArray().length === 0) {
                throw new Error('features.getArray().length equals 0. Click missed any features.');
            }
        } catch (e) {
            console.error(e.message);
            return;
        }
        //_this.composeOutput(feature, )  
        //console.log('@ ' + new Date().getMilliseconds())
        var selectedFeature = features.getArray()[0];
        //console.log(`selectedFeature=${selectedFeature.getProperties()}`);
        _this.handleFeature(selectedFeature);
    }

    /**
     * Defines interactions with features in UI.
     * @param  {[type]} selectedFeature [description]
     * @return {[type]}                 [description]
     */
    this.handleFeature = function(selectedFeature){
        var feature_name = selectedFeature.getProperties()['name'];
        var geometry = selectedFeature.getGeometry();
        var TreeView_node;


        //Reselecting node on the TreeView list.
        if(checkLocation('paleonimi')){
            var paleonym_id;

            if(_this.getCandidatePaleonymIDS())
            {
                let getPaleonymIdsOnCurrentFeature = function ()
                {
                    return selectedFeature.getProperties()['paleonyms'];
                }

                if(getPaleonymIdsOnCurrentFeature())
                {
                    let matchingPaleonym = getPaleonymIdsOnCurrentFeature().filter(function(paleonym){
                        return _this.getCandidatePaleonymIDS().find(function(id){
                            return id === paleonym.id;
                        })
                    })
                    if(matchingPaleonym.length < 1)
                    {
                        throw new Error('This should **always** return an array of length 1. Actual lenghth=' + matchingPaleonym.length + '! Halting execution.');
                        return false;
                    }
                    paleonym_id = matchingPaleonym[0].id;
                    // Resetting this variable for paleonym interface. 
                    // With paleonyms interface our features are actually toponyms(!).
                    feature_name = matchingPaleonym[0].name;
                }
            }
            else
            {
                paleonym_id = selectedFeature.getProperties()['paleonym_id'];
            }

            TreeView_node = document.querySelector(`[data-name="${feature_name}"]`)

            // Getting paleonym.
            _this.tree.getSinglePaleonym(paleonym_id); //Requires paleonym_id !!
        }
        else // We are on Toponym page.
        {
            var toponym_id = selectedFeature.getProperties()['toponym_id'];
            TreeView_node = document.getElementById(toponym_id);
            // Getting paleonyms.
            _this.tree.getPaleonyms(toponym_id);
            //new Etymology(toponym_id); Need to handle this forom ToponymTreeView
            TreeView_node.scrollIntoView({ behavior: "auto", block: "center" });
            _this.tree.deselect();
            _this.tree.select_node(TreeView_node);
            
        }

    }

    this.select.on('select', this.markerSelection);
    
    //var parser = new JsonStreamParser();

    var format = source.getFormat();
    var jsonString = function (features){
        return {
            "type": "FeatureCollection", 
            "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::3912" } },
            "features": features
        }
    };
    
    /**
    * Check location and initiate lists. See in class definitions
    * for further explainations.
    */
    if(checkLocation('paleonimi'))
    {
        /**
         * Paleonyms
         */
         
        _this.list = new List({
            map_app: _this
        });
        
        _this.tree = new PaleonymsTreeView(_this);
        /**
         * Store the returned objects in memory then render them on the page.
         * Create a vector layer and load it to the webmap.
         * @param  {Array of GeoJSON objects} response [description]
         * @return {Object}          [description]
         */
        let callback_list = function(response){
            
            _this.list.appendData(response);
            _this.tree.clear();
            _this.tree.buildTree(_this.list.getData());
            try
            {
                let minMaxDates = _this.list.dates(_this.list.getData());
                //console.log(minMaxDates);
                _this.timeSlider.updateOptions({
                    start: [minMaxDates.min, minMaxDates.max],
                    range: {
                        'min': minMaxDates.min,
                        'max': minMaxDates.max
                    }
                });
            }
            catch(err)
            {
                console.log(err.message);
            }

        }
        let callback = function(response){

            source.addFeatures(format.readFeatures(jsonString(response)));
            _this.featuresLayer = _this.map.getLayers().getArray()[1];

            if(!toggle_markers)
            {
                toggle_markers = {checked: false};
            }
            if(!toggle_markers.checked)
            {
                _this.featuresLayer.setStyle(HiddenStyle);
            }
        };
        ajaxStream(callback_list, this.region, 'features_hook.php');
        ajaxStream(callback, this.region, '../caches/toponym_markers.json');
    }
    else
    {
        /**
         * Toponyms
         */
        _this.list = new List({
            map_app: _this,
            ajaxTrigger: 'getToponymMarkers.php'
        });

        _this.tree = new ToponymsTreeView(_this);
        
        let callback = function(response) { // Callback function defined here!
            //var result = parser.parse(response).getParsed();
            //console.log(response)
            _this.list.appendData(response);
            _this.tree.clear();
            _this.tree.buildTree(_this.list.getData());


            // Set source of vector layer only if markersShow be true.
            
            //console.log(jsonString(response));
            
            source.addFeatures(format.readFeatures(jsonString(response)));

            _this.featuresLayer = _this.map.getLayers().getArray()[1];
                       
            //console.log(_this.featuresLayer);
            //console.log(_this.list.getData());

            try
            {
                
                let minMaxDates = _this.list.dates(_this.list.getData());
                //console.log(minMaxDates);
                
                _this.timeSlider.updateOptions({
                    start: [minMaxDates.min, minMaxDates.max],
                    range: {
                        'min': minMaxDates.min,
                        'max': minMaxDates.max
                    }
                });
                
            }
            catch(err)
            {
                console.log(err.message);
            }

            
            //console.log(_this.featuresLayer);

            if(!toggle_markers.checked)
            {
                _this.featuresLayer.setStyle(HiddenStyle);
            }
            //result = false; //parsedResult = false;
        };
        
        ajaxStream(callback, this.region, 'caches/toponym_markers.json');
    }
    

    /**
     * UI node references
     */
    this.region = document.getElementById('region'); 
    // Is this used anywhere outside main?
    // A: It is used inside main app in ajax callbacks.
    
    var timeSliderNode = document.getElementById('timeslider');
    this.timeSlider = new noUiSlider.create(timeSliderNode, {
        start: [0, 1],
        connect: true,
        range:{
            'min': 0,
            'max': 1
        },
        tooltips: true,
        step: 1,
        
        format: wNumb({
            decimals: 0
        }),
        pips: {
            mode: 'steps',
            filter: function(value, type){
                if(value % 25){
                    return -1;
                }
                let mod = value % 100;
                if(mod){
                    return mod === 50 ? 2 : 0;
                }
                return 1;
            }
        }
    });

    /***
     * Search tree reference
     */

    var searchTree = new SearchTree({
        map_app: this,
        listNode: document.getElementById('cpList'),
        input: document.getElementById('find'),
        btn_clear: document.getElementById('clear_search'),
        btn_prev: document.getElementById('find_prev'),
        btn_next: document.getElementById('find_next'),
        span_current: document.getElementById('current'),
        span_total: document.getElementById('total')
    })

    this.getSearchTree = function(){
        return searchTree;
    }

    /**
     * Data filters
     * Search filter nodes on UI.
     */

    var toggle_filter_settings;
    var toggle_markers;
    var nameSearchNode;
    var regionNode;
    var time_selector;
    var typologyNode;
    var resetBtn;
    this.setFilterNodeReferences = function()
    {
        toggle_markers = document.getElementById('toggle_markers');
        nameSearchNode = document.getElementById('isci');
        regionNode = document.getElementById('region');
        time_selector = document.getElementById('time_selector'); // Select field that sets the sort of temporal search: search by tempus post quem (mindate), tempus ante quem  (maxdate) or between both. Selector is currently not implemented.
        typologyNode = document.getElementById('typology');
        resetBtn = document.getElementById('reset_filters_btn');

        //setTimeout(function(){console.log(`Testing dom references`, {toggle_markers})}, 500);
        /**
         * Time selector is hidden. Setting default value.
         */
        if(!time_selector)
        {
            time_selector = {value: 'both'};
        }

        if(!typologyNode)
        {
            typologyNode = {checked: false};
        }
    };
    this.setFilterNodeReferences();

    this.getFilterNodeReferences = function()
    {
        return {
            toggle_markers: toggle_markers,
            nameSearchNode: nameSearchNode,
            regionNode: regionNode,
            time_selector: time_selector,
            typologyNode: typologyNode,
            resetBtn: resetBtn
        }
    };


    /**
     * define event callbacks
     *
     * 1. markersCallback
     * 2. regionCallbak
     * 3. timeSliderCallback
     */

    /**
     * If toggle_markers checkbox is present ...
     */
    try
    {
        if(toggle_markers){
            toggle_markers.addEventListener('input', function(evt){
                evt.preventDefault();
                if(_this.featuresLayer)
                {
                    _this.featuresLayer.setStyle(HiddenStyle);

                    if(toggle_markers.checked)
                    {
                        _this.featuresLayer.setStyle(FeatureStyles);
                    }
                    
                    _this.searchFiltersInteractions().toggleSettingsIcon();
                }
            })
        }
    }
    catch(err)
    {
        console.log(err.message);
    }
    
    _this.selectedRegions = new MultiCheckBox(regionNode);
    _this.selectedTypes = new MultiCheckBox(typology);
    regionNode.addEventListener('input', function(evt){
        setTimeout(function(){
            let filteredData = _this.list.filter({
                region: _this.selectedRegions.getSelectedValues(),
                name: nameSearchNode.value,
                metaTypes: _this.selectedTypes.getSelectedValues(),
                dates: _this.timeSlider.get(true),
            }); 
            _this.updateApp(filteredData);
            _this.searchFiltersInteractions().toggleSettingsIcon();
        }, 50);
    });

    /**
     * Temporal filtering section. 
     */
    timeSliderNode.noUiSlider.on('change.one', function(values, handle){
        dates = _this.timeSlider.get(true); 
        //console.log('slider.dates: ', dates);
        /**
         * Filtering is done via List.filter() method. The method retrieves the dates 
         * by itself when invoked. Do I need to decouple this? It is not obvious by 
         * itself.
         */
        let test;
        try
        {
            if(!(test = _this.list.getData()))
            {
                throw new Error('No data retrieved to test.');
                //console.error('dates:', dates);
            }
        }
        catch(err)
        {
            console.error(err.message);
        }

        /**
         * Firing List.filter() method and storing in filtered class property.
         */
        var filtered = _this.list.filter({
            region: _this.selectedRegions.getSelectedValues(),
            name: nameSearchNode.value,
            metaTypes: _this.selectedTypes.getSelectedValues(),
            dates: _this.timeSlider.get(true),
        });
        //console.log(_this.map.getLayers().getArray()[1].getSource().getFeatures());
        
        /**
         * Update page with filtered data ...
         */
        _this.updateApp(filtered);


        if(! checkLocation('paleonimi')) 
        {
            /**
             * recentPaleonyms gets any recently displayed paleonyms. It is 
             * specifically bound to Toponyms page. 
             * @type {[type]}
             */
            var recentPaleonyms = _this.paleonymsRepository.getData();

            if(recentPaleonyms)
            {
                document.getElementById('izpisi').innerHTML = recentPaleonyms;

                var paleonymsTimeFilter = new PaleonymsTimeFilter({
                    dates: dates,
                    paleonyms: document.querySelectorAll('.single-paleonym')

                });
            }
            _this.tree.prune(filtered);
            _this.keepSelectedFeatures({
                selectedFeatures: _this.select.getFeatures().getArray(),
                filteredFeatures: _this.map.getLayers().getArray()[1].getSource().getFeatures(),
                filterCriterion: 'toponym_id'
            });
        }
        else
        {
            _this.keepSelectedFeatures({
                selectedFeatures: _this.select.getFeatures().getArray(),
                filteredFeatures: _this.map.getLayers().getArray()[1].getSource().getFeatures(),
                filterCriterion: 'paleonym_id'
            });
        }

        /**
         * Reselect the object on the tree list.
         */
        var selected_node = document.querySelector(`[data-toponym_id='${_this.paleonymsRepository.getToponymId()}']`)
        if(selected_node)
        {
            _this.tree.select_node(selected_node);
            _this.tree.getSelectedNode().scrollIntoView({behavior: "auto", block:"center"});
        }
    }); 
    /**
    * End of timeslider handle interaction event listener.
    */

    typologyNode.addEventListener('input', function(evt){
        evt.preventDefault();
        setTimeout(function(){
            let filteredData = _this.list.filter({
                region: _this.selectedRegions.getSelectedValues(),
                name: nameSearchNode.value,
                metaTypes: _this.selectedTypes.getSelectedValues(),
                dates: _this.timeSlider.get(true),
            });
            _this.updateApp(filteredData);
            _this.searchFiltersInteractions().toggleSettingsIcon();
        }, 50);
    });

    resetBtn.addEventListener('mousedown', function(evt){
        evt.preventDefault();
        _this.searchFiltersInteractions().reset();
        setTimeout(function(){
        let filteredData = _this.list.filter({
            region: _this.selectedRegions.getSelectedValues(),
            name: nameSearchNode.value,
            metaTypes: _this.selectedTypes.getSelectedValues(),
            dates: _this.timeSlider.get(true),
        });
        _this.updateApp(filteredData);
        _this.searchFiltersInteractions().toggleSettingsIcon();
        }, 50);
    });
    /*
    this.map.on('click', function(evt) {
        evt.preventDefault();
        
        if(_this.select.getFeatures())
        {
            //console.log(`map event=${evt.type}.`);
            var features = [];
            _this.map.forEachFeatureAtPixel(evt.pixel, function(feature){
                //console.log(`map event=${evt.type}.`);
                console.log(`${typeof feature}`)
                features.push(feature);
                return feature;
            });
            
            _this.clearOutput();
            if(!features[0]){
                //console.log(`firstFeature=${firstFeature}`);
                return false;
            }
            _this.handleFeature(features[0]);
            //_this.map.getOverlays().clear();
        }
    });
    */
    return this;
};

MapApp.prototype.updateApp = async function(filteredData){
    const _this = this;
    let spinner = new loadingSpinner();
    spinner.start('vsebina');
    //console.log({spinner});
    let searchByName = this.getFilterNodeReferences().nameSearchNode.value;
    this.tree.clear();
    //modify here!
    //
    

    
    //console.log("UpdateApp: calling buildTreeInsertSort");
    spinner.end();

    if(filteredData.length > 0)
    {
        switch (checkLocation('paleonimi')) 
        {
            case true: await this.tree.buildTree(filteredData);
            break;

            case false: !searchByName ? await _this.tree.buildTree(filteredData) : await _this.tree.buildTreeInsertSort(filteredData);

                let format = source.getFormat();
                source.clear();
                let geoJSON = {
                "type": "FeatureCollection", 
                "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::3912" } },
                "features": filteredData
            };
            source.addFeatures(format.readFeatures(geoJSON));
             let extent = source.getExtent();
            this.map.getView().fit(extent, {
                nearest: true,
                padding: [190,190,190,190],
                duration: 1000,
            })
            break;
        }
    }
    else
    {
        this.notifications({
            type: 'message',
            message: 'Ni rezultatov, ki bi ustrezali izbranim kriterijem.'
        })
    }
}

/**
 * Keeps state of the input nodes and toggles the cog icon color that 
 * signifies that default values have been changed. 
 */
MapApp.prototype.searchFiltersInteractions = function(){
    //var searchFilters = new SearchFilters();
    var nodeReferences = this.getFilterNodeReferences();
    var _this = this;

    var regionNode = nodeReferences.regionNode;
    var typologyNode = nodeReferences.typologyNode;
    var toggle_markers = nodeReferences.toggle_markers;
    var nameSearchNode = nodeReferences.nameSearchNode;
    var time_selector = nodeReferences.time_selector;
    return {
        toggleSettingsIcon: function()
        {
            // Preform inputs states validation and set the icon class accordingly
            var settings_label = document.getElementById('srch-sttngs-btn');
    
            ( !regionNode.querySelector('#rg_00').checked || time_selector.value !== 'both' || toggle_markers.checked !== false || !typologyNode.querySelector('#ty_00').checked) ? settings_label.classList.add('active') : settings_label.classList.remove('active');
        },
        reset: function()
        {
            /*var regionNode = nodeReferences.regionNode;
            var typologyNode = nodeReferences.typologyNode;
            var toggle_markers = nodeReferences.toggle_markers;
            var nameSearchNode = nodeReferences.nameSearchNode;
            */

            nameSearchNode.value = '';
            regionNode.querySelector('#rg_00').checked = true;
            _this.selectedRegions.selectAllNodes();
            typologyNode.querySelector('#ty_00').checked = true;
            _this.selectedTypes.selectAllNodes();
            if(!toggle_markers)
            {
                toggle_markers.checked = true;
                toggle_markers.dispatchEvent(new Event('input'));
            }
        }
    }
}

MapApp.prototype.defineNewVector = function(options){
    try{
        if(!options.name)
        {
            throw new Error('Method requires an object with property name as parameter.');
        }
        if(options.features === undefined)
        {
            throw new Error('Features property of the options parameter is undefined. Please rectify.');
        }

    }
    catch(err)
    {
        console.error(`${err.name} ${err.message} Occuring at line ${err.lineNumber}.`);
    }
    const provideWithFeatures = function(featureProperties)
    {
        var jsonString = {
            "type": "FeatureCollection", 
            "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::3912" } },
            "features": featureProperties
        };

        let features = sourceFormat.readFeatures(jsonString);
        layer.getSource().addFeatures(features);
    }

    var name = options.name;
    var sourceFormat = new GeoJSON({
        dataProjection: 'EPSG:3912',
        featureProjection: 'EPSG:3857'
    });

    //console.log(features.constructor.name);
    var layer = new VectorLayer({
        name: name,
        source: new VectorSource({
            format: sourceFormat
        })
    })

    layer.setStyle(FeatureStyles);

    if(options.features !== false)
    {
        //console.log(`FeatureStyles is not falsy`);
        provideWithFeatures(options.features);
    }    
    return layer;
}

MapApp.prototype.removeVector = function(criteria)
{
    var _this = this;
    try{
        if(!criteria){
            throw new Error(`Method requires a criteria object passed as parameter, ${null} given.`)
        }
        if(criteria.length > 1){
            throw new Error(`Please proivde a single criterion, ${criteria.length} given.`);
        }
        if(criteria.length < 1)
        {
            throw new Error(`This method requires a single criterion, ${cirteria.length} given.`);
        }
        // Run all code here.
        
        var criterion;
        var criterionName;
        for(var key in criteria)
        {
            if(criteria.hasOwnProperty(key))
            {
                criterionName = key;
                criterion = criteria[key];
            }
        }
        var layers = this.map.getLayers().getArray();
        layers.every(function(layer){
            if(layer.get(criterionName) === criterion)
            {
                _this.map.removeLayer(layer);
                console.log(`Vector layer ${criterion} removed.`);
            }
            return true;
        });
    }
    catch(err)
    {
        console.error(err.message);
    }

    return this;
}

MapApp.prototype.exposeLayerKeys = function(layersArray){
    layersArray.forEach(function(layer){
        console.log(`keys=${layer.get('title')}, ${layer.get('name')}`);
    })
}

MapApp.prototype.generateOutput = function(options) {
    // Is this still valid after refactoring? Nope.
    try{ 
        var feature = options.feature;
        var node = options.node;
        var toponymName;
        var type_list;
        var note;
        var id;
    
        if (feature) {
            toponymName = feature.getProperties()['name'];
            note = feature.getProperties()['note'];
            id = feature.getProperties()['toponym_id'];
            type_list = feature.getProperties()['type_list'];
            document.getElementById('toponym_name').innerHTML = toponymName;
            document.getElementById('type_list').innerHTML = type_list;
            document.getElementById('note').innerHTML = note;
        }
        if (!feature && node) {
            document.getElementById('toponym_name').innerHTML = node.getAttribute('data-name');
            document.getElementById('note').innerHTML = '';
        }
    }
    /*
    catch(err)
    {
        console.error(`generateOutput method returned an error.\n Error ${err.name}: ${err.message}`)
    }
    */
};

MapApp.prototype.clearOutput = function() {
/*    document.getElementById('toponym_name').innerHTML = "";
    document.getElementById('type_list').innerHTML = "";
    document.getElementById('note').innerHTML = "";*/
    document.getElementById('izpisi').innerHTML = "";
}

MapApp.prototype.getMarkerByToponymId = function(id) {
// Called inside TreeView.treeNode.eventListener('click'). Makes sense to have it
// defined here, because it deals with map objects.
    //console.log('getMarkerByToponymId', id);
    try
    {
        //console.log(this.map.getLayers().getArray()[1].getSource());
        if(!(this.layerSourceFeatures = this.map.getLayers().getArray()[1].getSource().getFeatures()))
        {
            throw new Error('No features on the map');
        }
        var results = [];

        for (var i = this.layerSourceFeatures.length - 1; i >= 0; i--) {
            var currentFeature = this.layerSourceFeatures[i];
            var toponym_id = currentFeature.getProperties()['toponym_id'];
            //var toponymName =  currentFeature.getProperties().name;
            //var coordinates = currentFeature?.getGeometry().getCoordinates();
                
            var id_matched = false;
            if (parseInt(toponym_id) === parseInt(id)) {
                id_matched = true;
/*
                console.log({
                    'iterator': i,
                    'toponym_id': toponym_id, 
                    'toponym_name': toponymName, 
                    'coordinates': coordinates
                });
*/
                results.push(currentFeature);
            }
        }
        return results;
    }
    catch(err)
    {
        console.error(err);
    }
    //console.log(match_id);
    if (!match_id) {
        //this.map.getOverlays().clear();
        return false;
    }
    return this;
};

/**
 * This method is necessary for paleonyms view, but only if we use
 * paleonym markers vector layer.
 * 
 * Better to load toponyms as layer and set text from
 */ 
MapApp.prototype.getMarkersByName = function(name){
    var _this = this;
    // Called inside ToponymTreeView.treeNode.eventListener('click').
    //name = `/${name}/`;
    //this.layerSourceFeatures = this.map.getLayers().getArray()[1].getSource().getFeatures();
    //console.log(name, _this.list.getData().length);
    var results = [];
    for(var i = _this.list.getData().length - 1; i >= 0; i--){
        var currentFeature = _this.list.getData()[i];
        //console.log(currentFeature);
        var featureName = currentFeature.properties.name;
        
        if(featureName === name){
            results.push(currentFeature);
        }
    }

    return results;
};

MapApp.prototype.getMarkersByToponymId = function(id, name){
    try
    {
        if(!id)
        {
            throw new Error('Prease provide id parameter to method getMarkersByToponymId.');
        }
    }
    catch(err)
    {
        console.error(err);
    }
    try
    {
        if(!name)
        {
            throw new Error('Please provide parameter name to method getMarkersByToponymId.');
        }
        for (var i = _this.layerSourceFeatures.length - 1; i >= 0; i--) {
            var currentFeature = _this.layerSourceFeatures[i];

            for (var j = 0; j < toponym_ids.length; j++) {
                var currentId = toponym_ids[j];
                //console.log('j:',j,'currentId:',currentId, 'currentToponymId:',currentFeature.toponym_id);
                if(currentId === +currentFeature.getProperties().toponym_id)
                {
                    console.log(currentFeature);
                    results.push(currentFeature);
                    toponym_ids.splice(j,1);
                }
            }
            if(toponym_ids.length === 0)
            {
                break;
            }

        }
    }
    catch(err)
    {
        console.error(err);
    }
    var toponym_ids = JSON.parse(id);
    var results = [];
    var _this = this;
    this.layerSourceFeatures = this.map.getLayers().getArray()[1].getSource().getFeatures();
    //console.log(this.layerSourceFeatures[1]);
    /*
    console.log(this.map.getLayers().getArray()[0].get('name'));
    for(var i = this.layerSourceFeatures.length - 1; i >= 0; i--){
        var currentFeature = this.layerSourceFeatures[i];
        var toponym_id = currentFeature.getProperties()['id'];
        
        if(toponym_id === id){
            //console.log(currentFeature);
            //currentFeature.getStyle().setText(name);
            results.push(currentFeature);
        }
    }

    */
    return results;
}


/**
 * method puts information overlay on map.
 *
MapApp.prototype.displayFeatureInfo = function(feature, coord) {
    var _this = this;
    var attributeDiv = document.createElement('div');
    attributeDiv.classList = 'popup ol-popup';
    this.map.getOverlays().clear();

    //console.log('displayFeatureInfo: ' + feature.length);

    if (feature) {
        var id = feature.getProperties()['toponym_id'];
        var name = feature.getProperties()['name'];
        var note = feature.getProperties()['note'];
        var geo_descr = feature.getProperties()['geo_description'];
        var types_list = feature.getProperties()['type_list'];
        var toponym_id = feature.getProperties()['toponym_id'];
        var heading = document.createElement('h4');
        var closer = document.createElement('div');
        var toParagraph = function(content) {
            var node = document.createElement('p');
            node.innerHTML = content;
            return node;
        };
        var overlay = new Overlay({
            element: attributeDiv,
            position: coord,
            positioning: 'center-center'
        });

        //console.log(feature.getProperties());

        heading.innerHTML = name;
        attributeDiv.appendChild(heading);

        closer.classList = "ol-popup-closer";
        attributeDiv.appendChild(closer);

        attributeDiv.appendChild(toParagraph('ID: ' + id));

        attributeDiv.appendChild(toParagraph('<em>' + types_list + '</em>'));

        attributeDiv.appendChild(toParagraph(geo_descr));

        attributeDiv.appendChild(toParagraph(note));

        closer.onclick = function() {
            _this.map.getOverlays().clear();
            _this.select.getFeatures().clear();
            closer.blur();
            return false;
        };
        this.map.addOverlay(overlay);
    }
    return this;
};
*/
MapApp.prototype.notifications = function(options)
{
    try
    {
        var type = options.type;
        var typeList = ['message', 'warning', 'error']
        var message = options.message
    
        var node = document.getElementById('notification');
        var span = document.createElement('SPAN');
        var closeBtn = document.createElement('BUTTON');
        closeBtn.className = 'btn_close';
    
        closeBtn.addEventListener('mousedown', function(evt){
            evt.preventDefault();
            node.innerHTML = '';
        })
    
        node.innerHTML = "";
        node.classList.add("notification");
        if(!message)
        {
            return false;
        }
        if(typeList.indexOf(type) == -1)
        {
            throw new Error(`type has to be either message, warning or error ${type} given`);
        }
        node.classList.add(type);
        span.innerHTML = message;
        node.appendChild(span);
        node.appendChild(closeBtn);
    }
    catch(err)
    {
        console.error(err.name, err.message);
    }
};


MapApp.prototype.keepSelectedFeatures = function(options)
{
    var selectedFeatures = options.selectedFeatures;
    var filteredFeatures = options.filteredFeatures;
    var filterCriterion = options.filterCriterion;

    if(! selectedFeatures || ! filteredFeatures || ! filterCriterion)
    {
        throw new Error('Please provide all the necessary arguments for the method. Three arguments are needed, ' + options.length + ' have been provided.');
    }
    if (typeof filterCriterion !== 'string')
    {
        throw new Error('filterCriterion argument needs to be a string.' + typeof filterCriterion + 'given.');
    }
    //console.log(typeof selectedFeatures);
    //console.log(typeof filteredFeatures);

    var lookupHash = {};
    filteredFeatures.forEach(feature => {
        /**
         * Make a JSON dictionary item {ID:FEATURE} for the filtered features dataset.
         */
        lookupHash[feature.getProperties()[filterCriterion]] = feature;
    });
    selectedFeatures.forEach(feature => {
        var i = feature.getProperties()[filterCriterion];
        if(lookupHash[i])
        {
            this.select.getFeatures().clear();
            this.select.getFeatures().push(lookupHash[i]);
        }
    })
}


/**
 * This one utilizes SQL. Not in use right now. 2022-05-03.
 * @param {[type]} options [description]
 */
var FindToponym = function(options) {
    'use strict';
    var map_app = options.map_app;
    var query = options.query;
    var region = options.region.value;
    console.log('FindToponym constructor, region: ' + region);
    ajaxCall(
        function(result) {
            map_app.tree.clear();
            let nodes = map_app.tree.buildTree(result);
            // Following method no longer in use. Instead appendData() should be used.
            // Need to refactor PHP script too if to be used again -- for streaming.
            map_app.tree.addList(nodes);
        },
        query + '&region=' + region,
        './queryToponym.php'
    );

};

var FetchToponymMarkers = function(region){
  ajaxCall(function(result) {
            
        },
        region,
        'getToponymMarkers.php'
    );
}

function init() {
    'use strict';
    document.removeEventListener('DOMContentLoaded', init);
    var scaleControl = new ScaleLine({
        units: 'metric'
    });

    const map = new Map({
        target: 'map',
        layers: [
            new TileLayer({
                name: OSM,
                source: new OSM({
                    attributions: [
                        'Kartografska podlaga: © <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>-sodelavci | '
                    ]
                })
            })
            /*
            new TileLayer({
                name: 'OpenTopoMap',
                source: new XYZ({
                    url: 'https://b.tile.opentopomap.org/{z}/{x}/{y}.png',
                    attributions: [
                        'Podatki: Geodetska uprava Republike Slovenije, ZRC SAZU Zgodovinski inštitut Milka Kosa',
                        'Podatki: © <a href="https://openstreetmap.org/copyright">OpenStreetMap</a>-sodelavci, SRTM | Kartografija: © <a href="http://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',

                    ]
                })
            })
            */
        ],
        view: new View({
            center: [1649262, 5799642],
            zoom: 9,
            minZoom: 8,
            maxZoom: 19.0,
            extent: [1361673, 5577149, 1922791, 6021797]

        }),
        controls: new defaultControls({
            attributionOptions: {
                collapsible: true,
                collapsed: true
            }
        }).extend([scaleControl]),

    });

    /**
    * 
    */
    //var styleFunction = new FeatureStyles()
    var markersLayer = new VectorLayer({ 
        source: source, // source variable defined globally at beginning of script, assigned with value inside FetchToponymMarkers object called above. 
        name: 'Toponyms markers', 
        styleFunction: FeatureStyles, //Change made 20220104. 
        strategy: Bbox,
        declutter: false
    });
    map.addLayer(markersLayer);

    //var selectInteraction = new Select();

    //map.getInteractions().extend([selectInteraction]);

    var output = document.getElementById('izpisi'); // This has to change for paleonyms or not?

    var map_app = new MapApp({
        map: map,
        target: output
    });

        /**
     * Reset filter values on load
     */
    map_app.searchFiltersInteractions().reset();
    map_app.getSearchTree().reset();

    //new FetchToponymMarkers(map_app.region.value);
};


document.addEventListener('DOMContentLoaded', init);