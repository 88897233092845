/**
 * Iterate through records and return those, that satisfy the time limit criteria.
 * Records are loaded thru options object that accepts data from PaleonymsRepository
 * object. See in code at implementation site - ToponymsTreeView, clickedNode.
 */
var PaleonymsTimeFilter = function(options)
{
	'use strict';

	console.log('PaleonymsTimeFilter');
	var minDate = options.dates[0];
	var maxDate = options.dates[1];
	var paleonyms = options.paleonyms;
	var noOfPaleonymsOnCard = paleonyms.length;

	paleonyms.forEach(function(paleonym){
		var i = 0; // Index of removed records for current paleonym.
		var records = paleonym.querySelectorAll('.record');
		records.forEach(function(record){
			var mentionYear = castAsInteger(record.dataset.mentiondate);
			
			minDate = castAsInteger(minDate);
			maxDate = castAsInteger(maxDate);

			assertTypeEquality(mentionYear, minDate);
			assertTypeEquality(mentionYear, maxDate);

			if(!(minDate <= mentionYear && mentionYear <= maxDate))
			{

				console.log((minDate <= mentionYear && mentionYear <= maxDate))	
				// console.log({removing: {
				// 	minDate: minDate, 
				// 	mentionYear: mentionYear, 
				// 	record_mentiondate: record.dataset.mentiondate, 
				// 	maxDate: maxDate}});
				i++; // Advance index.
				record.remove();
			}
			else
			{
				//console.log({mentionYear})
			}

			// Check no. of removed records vs. total no. of records
			if(records.length - i === 0)
			{
				paleonym.remove();
				// Update counter of paleonyms displayed.
				noOfPaleonymsOnCard = noOfPaleonymsOnCard - 1;
				if(noOfPaleonymsOnCard < 1)
				{
					//console.log(`Number of paleonyms of card is ${noOfPaleonymsOnCard}`)
					// No more data on this toponym, clearing output div.
					var dataView = document.getElementById('izpisi').childNodes;
					//console.log(dataView);
					Array.from(dataView).forEach(node => {
						node.remove();
					})
				}
			}
		})
	})

};

export default PaleonymsTimeFilter;