
var Etymology = function(id) {
    'use strict';
    var _this = this;

    this.button = document.getElementById('etym');

    ajaxCall(function(result) 
    {
        if(_this.button)
        {
            document.getElementById('etym_txt').innerHTML = result;
            setTimeout(function(){
                _this.button.dataset.id = id;
            }, 50);
        }
    },
        id,
        '/getEtymology.php'
    );
};

export default Etymology;