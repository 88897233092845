import EscapeString from '../utils/EscapeString';

const distinct = function(value, index, self) {
    return self.indexOf(value) === index;
};

/**
 * Object to manage a nested list of toponyms on the UI. Holds entire dataset in storage 
 * property. Has methods to extract properties from markers array, filtering and reseting 
 * page representation. 
 * Returns a HTML DOM unordered list node and appends it to a div node of the interface.
 * @return [a bunch of nodes] [A regular html5 unordered list.] 
 */
var List = function(options) {
    'use strict';
    if (!(this instanceof List)) {
        throw new Error('List has to be constructed with the new keyword.');
    }
    this.map_app = options.map_app;
    //this.region = options.region; // Value passed on initialization in index.js. No longer needed.
    // Remains from when list contained ajax results from SQL query.
    //this.ajaxTrigger = options.ajaxTrigger;

    var storage = Array(); // An array to hold JSON representations of toponyms to be transformed into html LI elements.
    /**
     * This will store all child objects i.e. those that have parent_id property specified.
     */
    var childRecords = {};
    var rootRecords = {};

    var storeToLookupList = function(index, item, storage)
    {
        // If index does not exist yet, create it and make it an empty array.
        if(!storage[index])
        {
            storage[index] = [];
        }
        storage[index].push(item);
    }

    this.appendData = function(data) {
        storage = storage.concat(data);
        data.forEach(function(record){
            const { properties: { toponym_id }, properties: { parent_id } } = record;
            if(record.properties.parent_id)
            {
                storeToLookupList(parent_id, record, childRecords)
            }
            
                storeToLookupList(toponym_id, record, rootRecords);
            
        })
    }

    this.getData = function() {
        return storage;
    }

    this.getChildRecords = function(id)
    {
        //console.log(id);
       /*if(!childRecords[id])
        {
            console.error("Something wrong. ", id);
        }*/ 
        return childRecords[id];
    }

    this.rootLookupById = function(id)
    {
        /*if(!rootRecords[id][0])
        console.log(id);
        {
            console.error("Something wrong. ", id);
        }*/
        return rootRecords[id][0];
    }

    return this;
};

/**
 * Iterate dataload and extract properties, forfeiting geometries.
 * @param  JSON dataload Load of JSON objects representing toponyms with coordinates.
 * @return {JSON array          Array of toponym properties, stripped of geometries
 */
List.prototype.extractProperties = function(dataload) {
    let properties = Array();

    dataload.forEach(function(row) {
        properties.push(row.properties);
    })
    return properties;
}

List.prototype.reset = function() {
    return this.getData();
}

List.prototype.getItemNamesByName = function(searchString)
{
    const resultset = [];
    const data = this.getData().forEach(function(item){
        const { properties: { search_name }, properties: { name } } = item;
        let escapedName = new EscapeString(searchString).get();
        
        let pattern = new RegExp(escapedName, 'i');

        if(!pattern.test(search_name))
        {
            return false;
        }
        let namePattern = new RegExp(name, 'i');
        
        if(resultset.some(function(current){
            return namePattern.test(current)}))
        {
            console.log(`${name} already in resultset`);
            return false;
        }
        resultset.push(name);
    });
    return resultset;
}

List.prototype.filter = function(options) {
    this.map_app.notifications({
        type: 'message',
        message: 'Posodabljam podatke ...'
    })
    let region = options.region; //document.getElementById('region').value; 
    let name = options.name; // document.getElementById('isci').value; 
    let metaTypes = options.metaTypes // Array.from(document.getElementById('typology')).filter(option => option.selected).map(option => option.value);
    let dates = options.dates; // this.map_app.timeSlider.get(true);
    let resultset = [];
    let filterDateBy = 'both'; // Done also in Search settings, where it has no effect, but it should be set there. Look into it, refactor. 

    /*
    console.log({
        region: region,
        name: name,
        metaTypes: metaTypes,
        
    });
    */
    const metaTypes2TypesArray = function(metaTypes)
    {
        try{
            if(!(metaTypes instanceof Array))
            {
                throw new Error(`metaTypes parameter needs to be an array.`);
            }
        }
        catch(err)
        {
            console.error(err.message);
        }

        const typology = [
            {
                "meta_name": "gore",
                "types": ["planina", "gora", "vrh", "grič", "hrib", "prelaz", "gorovje", "hribovje", "sedlo", "vzpetina", "pobočje"]
            },
            {
                "meta_name": "kraji",
                "types": ["naselje", "kmetija", "zaselek", "vas", "mesto", "trg", "ulica", "mestni predel", "predmestje", "del mesta", "mestna četrt", "trg v mestu", "sedež župnije", "sedež škofije", "obmestni predel"]
            },
            {
                "meta_name": "objekti",
                "types": ["cerkev", "grad", "dvor", "samostan", "pristava", "proštija", "kapela", "dvorec", "stolp", "brod", "obzidje", "kostnica", "špital", "most", "križ", "oltar", "objekt", "planinska pristava", "komenda", "stavba", "hospic", "trdnjava", "rudnik", "pokopališče", "utrdba", "zgradba", "pristanišče", "krstilnica", "tabor"]
            },
            {
                "meta_name": "vode",
                "types": ["reka", "potok", "močvirje", "jezero", "plitvina", "kanal", "soline"]
            },
            {
                "meta_name": "zemljišča",
                "types": ["rovte", "gorica", "ledina", "gozd", "pokrajina", "otok", "travnik", "urad", "dolina", "planota", "njiva", "predel", "neobdelano območje", "vinograd", "pašnik", "ozemlje", "ravnica", "plato", "kraška jama", "nenaseljeno območje"]
            }
        ];
        var typesArray = [];

        metaTypes.forEach(type => {
            typology.forEach(record => {
                if(record.meta_name === type)
                {
                    typesArray = typesArray.concat(record.types);
                }
            })
        })
        return typesArray;
    }
    
    let typesArray = metaTypes2TypesArray(metaTypes);

    //console.log(`All toponyms inside List.filter method`, this.toponyms);
    //console.log(`resultset`, resultset);

    if (dates) {
        this.getData().forEach(function(row, i) {
            /*
            try
            {
                //console.log(row.geometry, row.properties);
                if(!row.geometry)
                {
                    console.log(row.geometry, row)
                    throw new Error(`This object has no geometry ${row.properties.name}, $(row.properties.toponym_id).`);
                }
            }
            catch(err)
            {
                console.error(err.message);
            }
            */

            let mentionsList = row.properties.mentionsList;
            let fM = mentionsList[0];
            let lM = mentionsList[mentionsList.length - 1];

            let firstMention = function() {
                
                if (filterDateBy === 'first') {
                    //console.log('first mention true, validating ...', dates);
                    //console.log(filterDateBy === 'first');
                    fM >= Number(dates[0]) && fM <= Number(dates[1]) ? searchByRegion() : false /*console.log('first Mention validation false. fM value = ', fM, '\n dates[0] = ', dates[0], '\n dates[1] = ', dates[1])*/ ;
                } else {
                    lastMention();
                }
            };

            let lastMention = function() {
                if (filterDateBy === 'last') {
                    //console.log(`lastMention true, validating ...`, dates);
                    lM >= Number(dates[0]) && lM <= Number(dates[1]) ? searchByRegion() : false;
                } else {
                    betweenMention();
                }
            };

            let betweenMention = function() {
                if (filterDateBy === 'both') {
                    //console.log(`betweenMention function.`)

                    let matches = mentionsList.filter(mentionYear => Number(mentionYear) >= Number(dates[0]) && Number(mentionYear) <= Number(dates[1]));
                    matches.length > 0 ? searchByRegion() : false;
                    //console.log({dates});
                    //Number(Number(dates[0]) >= fM && Number(dates[1]) <= lM) || Number(dates[0]) <= lM && Number(dates[1]) >= fM  ? searchByRegion() : false;
                } else {
                    return false;
                }
            }

            let searchByRegion = function() {
                region.includes(row.properties.region.toLowerCase()) ? searchByMetaType() : false;

            };

            let searchByMetaType = function(){
                let state = false;
                row.properties.type_list.split(',').forEach(function loop(type){
                    if(loop.stop){return}

                    //console.log(`testing metaTypes`, type, typesArray.includes(type));
                    typesArray.includes(type) ? state = true : state = false;
                    if(state)
                    {
                        searchByName();
                        loop.stop = true;
                    }
                })
            }

            let searchByName = function() {

                //console.log(`searchByName row search_name=${row.properties.search_name}`);
                if (!name) {
                    //console.log('Name name not set, pushing row to resultset.', row.properties.name, row.properties.type_list); 
                    resultset.push(row);
                    return false;
                }

                let escapedName = new EscapeString(name).get();
                /*
                console.log({escapedName});
                console.log('searchByName, name filter WAS specified, validating ...', name);
                */
               
                let pattern = new RegExp('(?:\s|^)' + escapedName, 'i');

                //console.log(pattern.test(row.properties.search_name));

                const { properties: { search_name }, properties: { toponym_id } } = row;

                search_name.split(' ').forEach(function loop(word){
                    if(loop.stop)
                    {
                        return;
                    }
                    try 
                    {
                        if (pattern.test(word)) 
                        {
                            /*
                            console.log(`${i}: `, {pattern}, {word}, {toponym_id});
                            */
                            resultset.push(row);
                            loop.stop = true;
                        }
                    } 
                    catch (err) 
                    {
                        console.error(err.name, err.message);
                    }
                });

            };

            firstMention();
        });
        this.map_app.notifications({
            message: false
        });
        
        //console.log('filter() resultset=', {resultset});
        return resultset;
    }
}

/**
 * Method to get a flat array of unique mention dates
 * @param JSON array of objects
 * @return array of date litterals (years only)
 */
List.prototype.dates = function(dataload) {
    // Each object in the _dataload_ array has a firstMention 
    // and a lastMention property. We need to flatten this 
    // array, hence we first extract all the data and then 
    // concat the two arrays.

    // Need to parse ajax response as json! JS does not seem to
    // read http headers and will treat response data as string.
    
    const allMentions = dataload.reduce(function(mentions, {properties: {mentionsList}}){
        return mentions.concat(mentionsList).sort(function(a, b) { return a - b }).filter(distinct);
    },[]);
    


    var result = {
        min: allMentions[0],
        max: allMentions[allMentions.length - 1]
    };
    //console.log({result});
    return result;
};


export default List;