import LineString from 'ol/geom/LineString';
import Circle from 'ol/geom/Circle';
import { Icon, Circle as CircleStyle, Text, Fill, Stroke, Style } from 'ol/style';
import { getLength } from 'ol/sphere';

var image = new CircleStyle({
    radius: 6,
    fill: new Fill({
        color: 'rgba(0, 144, 197,0.4)'
    }),
    stroke: new Stroke({ color: '#0090c5', width: 1 })
});

var styles = {
    'Point': new Style({
        image: image
    }),
    'LineString': new Style({
        stroke: new Stroke({
            color: '#0090c5',
            width: 1,
        }),
    }),
    'MultiLineString': new Style({
        stroke: new Stroke({
            color: 'rgba(255,0,0,0)',
            width: 1,
        }),
        zIndex: 99,
    }),
    'MultiPoint': new Style({
        image: image,
    }),
    'MultiPolygon': new Style({
        stroke: new Stroke({
            color: 'yellow',
            width: 1,
        }),
        fill: new Fill({
            color: [100, 152, 210, 0.72],
        }),
        text: createTextStyle
    }),
    'Polygon': new Style({
        stroke: new Stroke({
            color: 'blue',
            lineDash: [4],
            width: 3,
        }),
        fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)',
        }),
    }),
    'GeometryCollection': new Style({
        stroke: new Stroke({
            color: 'magenta',
            width: 2,
        }),
        fill: new Fill({
            color: 'magenta',
        }),
        image: new CircleStyle({
            radius: 10,
            fill: null,
            stroke: new Stroke({
                color: 'magenta',
            }),
        }),
    }),
    'Circle': new Style({
        stroke: new Stroke({
            color: 'rgba(255,0,0,0.6)',
            width: 2,
        }),
        fill: new Fill({
            color: 'rgba(255,0,0,0.2)',
        }),
    }),
    'vode': new Style({
        stroke: new Stroke({
            color: '#6498d2',
            width: 2,
        }),
        fill: new Fill({
            color: [100, 152, 210, .9],
        }),
        zIndex: 98,
    })
};

/**
 * FeatuesStyles needs to be instantiated as a function not a class. 
 * @param {[type]} feature    [description]
 * @param {[type]} resolution [description]
 */
var FeatureStyles = function(feature, resolution)
{
    'use strict';
    try
    {
        if(this instanceof FeatureStyles)
        {
            throw new Error('FeatureStyles needs to be instantiated as a function, not as a class.');
        }
        if(!feature.getGeometry())
        {
            throw new Error('Feature undefined');
            
        }
    }
    catch(err)
    {
        console.error(err.message);
    }

    var featureType = feature.getGeometry().getType();

    if (featureType !== 'Point') {
        var sht_typology = feature.get('type_list');
        var reg_ex = new RegExp(['jezero', 'kanal', 'potok', 'reka'].join('|'), 'gi');
        if (reg_ex.test(sht_typology)) {
          //console.log(`This feature has geometry of type ${featureType} and style of 'vode'`, sht_typology);
          styles['vode'].setText(createTextStyle(feature, resolution, true, {color: 'white', width: 6}, {color: '#0090c5'}));
          return styles['vode']
        }
        //console.log(`This feature has geometry of type ${featureType}.`);
        styles[featureType].setText(createTextStyle(feature, resolution, false, {color: 'ivory', width: 6}, {color: '#3d1100'}));
    }
    //console.log(`This feature has geometry of type ${featureType}, predicted type is 'Point'.`);
    return styles[featureType];
};

/**
 * Creates style for feature text label.
 * @param  {[type]} feature    Feature of a vector layer.
 * @param  float resolution Provides feature resolution in map units per pixel.
 * @return Text            OpenLayers HTML canvas Text object to be drawn on map.
 */
var createTextStyle = function(feature, resolution, vode, stroke, fill) {
    var text = getText(feature, resolution);
    var fillColor = fill.color;
    var outlineColor = stroke.color;
    var outlineWidth = stroke.width;
    var lineHeigth = 1.618;
    var fontFamily = 'Arial';
    var placement = 'line';
    var baseline = 'bottom';
    var align = 'center';
    var font = 'italic bold 14px/1.618 Arial';
    var maxAngle = .2;

    /**
     * If we are not dealing with waters, then we are concerned with the font size and
     * length of labels on the map.
     */
    if (!vode) {
        /**
         * OpenLayers3 LineString object, part of geom. Needed for calculation of physical length of feature on the map.
         * @type {LineString}
         */
        var ls = new LineString(feature.getGeometry().getCoordinates())
        /**
         * Length of a linestring feature on the map in meters.
         * @type {float}
         */
        var $_length = Math.floor(getLength(ls));
        /**
         * Feature text to be displayed in label.
         * @type {text}
         */
        /**
         * Font constant describing the ratio between font height and average character width. Explained here: https://pearsonified.com/characters-per-line/ (retrieved 2021-06-08).
         * @type {array}
         */
        var fontConstant = {
            'Arial': 2.26,
            'Gil Sans': 2.58,
            'Verdana': 1.96
        };
        /**
         * Feature length expressed in pixels.
         * @type {integer}
         */
        var featureLength = Math.floor($_length / resolution);

        /**
         * Average character width to fit within the feature length.
         * @type {[type]}
         */
        var c_w = featureLength / text.length;

        /**
         * Font size (height) derived from fontConstant and featureLength. 
         * @type {[type]}
         */
        var f = Math.floor(fontConstant[fontFamily] * c_w);

        var max_font = 23;

        //console.log(`font size:${f}`);
        //console.log(`$_length: ${$_length} (in meters)`);
        //console.log(`resolution:${resolution}`)
        //console.log(`feature length in pixels: ${$_length / resolution}`);
        //console.log(`label text: ${text}\nlabel text length in characters:${text.length}`);
        baseline = 'middle';
        if (f <= max_font) {
            var fontSize = f + 'px';
        } else {

            var oversize_index = Math.floor((featureLength * fontConstant[fontFamily]) / (max_font * text.length));

            fontSize = max_font + 'px';
            var arr_filled = [];
            var space = ' ';
            for (var i = 0; i < text.length; i++) {
                var fuller = text[i] + String.fromCharCode(32).repeat(oversize_index);
                arr_filled.push(fuller);
            }
            text = arr_filled.join('');
            //fill space characters function fromCharCode(32);
        }

        font = `bold italic ${fontSize}/${lineHeigth} ${fontFamily}`;
        //console.log(getLength(ls));
        //console.log(fontSize);
        var offsetX = 0;
        var offsetY = 0;
        maxAngle = 0.6;
        //console.log(fillColor)
    }


    var text = new Text({
        textAlign: align,
        textBaseline: baseline,
        font: font,
        text: text,
        fill: new Fill({ color: fillColor }),
        stroke: new Stroke({
            color: outlineColor,
            width: outlineWidth
        }),
        offsetX: offsetX,
        offsetY: offsetY,
        placement: placement,
        maxAngle: maxAngle,
        overflow: false,
        rotation: 0,
        padding: [0, 5, 0, 5]
    })

    return text;
};

/**
 * Function to retrieve name from feature. Not needed but built for further flexibility.
 * @param  {[type]} feature    [description]
 * @param  {[type]} resolution [description]
 * @return {[type]}            [description]
 */
var getText = function(feature, resolution) {
    var text = feature.get('name');
    //console.log(text);
    return text;
};

/*
FeatureStyles.prototype.shadowStyle = new Stroke({
    color: '#fafafa',
    width: 6
});
*/

var SelectedStyle = function(feature, resolution) {
    var featureType = feature.getGeometry().getType();
    var iconLocation = require('/pics/marker.svg');

    //console.log(featureType);
    if (featureType === 'Point') {
        //var marker = require('/pics/marker.svg')
        return new Style({
            image: new Icon({
                scale: 2.4,
                anchor: [0.5, 1],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                src: iconLocation,
            })
        })

        /*
        styles[featureType].setImage({
          image: new Icon({
            anchor: [0.5, 1],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            src: 'pics/marker.svg'
          })
        });
        */
    }
    else
    {
        var sht_typology = feature.get('type_list');
        var reg_ex = new RegExp(['jezero', 'kanal', 'potok', 'reka'].join('|'), 'gi');
        if (reg_ex.test(sht_typology)) {
            console.log('vode slog', sht_typology);
            styles['vode'].setText(createTextStyle(feature, resolution, true, {color: 'white', width: 6}, {color: '#0090c5'}));
            return styles['vode']
        } else {
            console.log('styleFunction:', sht_typology);
            styles[featureType].setText(createTextStyle(feature, resolution, false, {color: 'ivory', width: 10}, {color: 'hotpink'/*'#3d1100'*/}));
            return styles[featureType];
        }
        
    }

};

var ResultsStyle = function(feature, resolution){
    var featureType = feature.getGeometry().getType();
    var style = feature.getStyle();

    if(featureType === 'Point'){
        var icon = style.getImage();
        icon.setScale(1);
        style.setImage(icon);
        return style;
    }
    return style;
}

var HiddenStyle = function(feature, resolution)
{
    return null;
};

export { FeatureStyles, SelectedStyle, HiddenStyle };